import { Component, Input, OnInit } from '@angular/core';

export type CustomPageActions = {
  onClick: any;
  label: string;
  icon: string;
  isDisabled?: any;
  customClass?: any;
  hidden?: boolean;
  disabledTooltip?: string;
  tooltip?: string;
  column?: boolean;
  row?: boolean;
};

@Component({
  selector: 'app-custom-page-actions',
  templateUrl: './custom-page-actions.component.html',
  styleUrls: ['./custom-page-actions.component.scss']
})
export class CustomPageActionsComponent implements OnInit {

  @Input() customPageActions: CustomPageActions[] = [];
  @Input() direction: 'flex-column' | 'flex-row' = 'flex-column';

  constructor() { }

  ngOnInit(): void {
  }

  onClickAction(action) {
    if (action.onClick) {
      action.onClick();
    }
  }

  checkIfDisabled(action) {
    return action.isDisabled;
  }
}
