import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-quick-search',
    templateUrl: './quick-search.component.html',
    styleUrls: ['./quick-search.component.scss']
})
export class QuickSearchComponent {

    @Input() searchPlaceholder: string;

    @Output() search = new EventEmitter();

    constructor() { }

    onSearch(event) {
        this.search.emit(event);
    }
}
