import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { VIDEO_GALLERY_COLLECTION } from '../../app.constants';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FireLoggingService } from './fire-logging.service';

@Injectable({
    providedIn: 'root',
})
export class VideoGalleryService {

    constructor(private firestore: AngularFirestore,
                private fireLogging: FireLoggingService) {
    }

    public getAllVideos(): Observable<any> {
        return this.firestore.collection(VIDEO_GALLERY_COLLECTION).get().pipe(
            map(res => {
                return res.docs.map(item => {
                    const data: any = item.data();
                    data.id = item.id;
                    return data;
                });
            }),
            catchError((error) => {
                const message = error.error ? error.error.message : error.message;
                this.fireLogging.sendErrorLog(`[getAllVideos(video-gallery.service.ts)] An error occurred while getting items into collection ${ VIDEO_GALLERY_COLLECTION }, details: ${ message || JSON.stringify(error) }`);
                return of({error});
            })
        );
    }

    public getAllVideosByDocId(docId: string): Observable<any> {
        return this.firestore
            .collection(VIDEO_GALLERY_COLLECTION)
            .doc(docId)
            .get()
            .pipe(
                map(res => {
                    const data: any = res.data();
                    data.id = res.id;
                    return data;
                }),
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    const path = `${ VIDEO_GALLERY_COLLECTION }/${ docId }`;
                    this.fireLogging.sendErrorLog(`[getAllVideosByDocId(video-gallery.service.ts)] An error occurred while getting items into collection ${ path }, details: ${ message || JSON.stringify(error) }`);
                    return of({error});
                })
            );
    }

    public getPortalVideoByProperty(property: string): Observable<any> {
        return this.firestore
            .collection(VIDEO_GALLERY_COLLECTION)
            .doc('portal')
            .get().pipe(
                map(res => {
                    return res.get(property);
                }),
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    const path = `${ VIDEO_GALLERY_COLLECTION }/portal`;
                    this.fireLogging.sendErrorLog(`[getPortalVideoByProperty(video-gallery.service.ts)] An error occurred while getting item into collection ${ path }, details: ${ message || JSON.stringify(error) }`);
                    return of({error});
                })
            );
    }

    public getVideoByDocumentAndByProperty(docId: string, property: string): Observable<any> {
        return this.firestore
            .collection(VIDEO_GALLERY_COLLECTION)
            .doc(docId)
            .get().pipe(
                map(res => {
                    return res.get(property);
                }),
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    const path = `${ VIDEO_GALLERY_COLLECTION }/${ docId }`;
                    this.fireLogging.sendErrorLog(`[getVideoByDocumentAndByProperty(video-gallery.service.ts)] An error occurred while getting item into collection ${ path }, details: ${ message || JSON.stringify(error) }`);
                    return of({error});
                })
            );
    }

    public getConsultancyProcessVideoByProperty(property: string): Observable<any> {
        return this.firestore
            .collection(VIDEO_GALLERY_COLLECTION)
            .doc('consultancy-process')
            .get().pipe(
                map(res => (res.get(property))),
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    const path = `${ VIDEO_GALLERY_COLLECTION }/consultancy-process`;
                    this.fireLogging.sendErrorLog(`[getConsultancyProcessVideoByProperty(video-gallery.service.ts)] An error occurred while getting item into collection ${ path }, details: ${ message || JSON.stringify(error) }`);
                    return of({error});
                })
            );
    }

    public getFaqVideoByProperty(property: string) {
        return this.firestore
            .collection(VIDEO_GALLERY_COLLECTION)
            .doc('faq')
            .get().pipe(
                map(res => (res.get(property))),
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    const path = `${ VIDEO_GALLERY_COLLECTION }/faq`;
                    this.fireLogging.sendErrorLog(`[getFaqVideoByProperty(video-gallery.service.ts)] An error occurred while getting item into collection ${ path }, details: ${ message || JSON.stringify(error) }`);
                    return of({error});
                })
            );
    }

}
