<div #topTitle class="top-title row mb-6 mt-6 px-0 mx-0 mb-7 space-content">
    <div class="col-sm-12">
        <div class="row position-relative">
            <div class="col-2">
                <button class="icon btn-muted back-to-list-button" *ngIf="showBackButton" (click)="backToList()">
                    <i class="material-icons">arrow_back</i>
                </button>
                <div *ngIf="showContent">
                    <ng-content select="[leftContent]"></ng-content>
                </div>
            </div>
            <div class="col-8 d-flex align-items-center justify-content-center">
                <div class="d-block">
                    <div class="d-flex w-fit-content m-auto">
                        <app-player-button *ngIf="videoName"
                                           [videoName]="videoName"
                                           bodyClass="mr-2"
                                           width="35px"
                                           backgroundColor="#0760ef"></app-player-button>
                        <h1 class="page-title text-center m-auto" [ngClass]="titleClass">{{title | translate}}</h1>
                        <small class="badge badge-secondary custom-badge-view-mode ml-2 my-auto" *ngIf="viewMode"
                               translate>
                            system.view-mode
                        </small>
                    </div>
                    <div class="d-flex mt-2 justify-content-center">
                        <small class="page-description text-center m-auto" *ngIf="description"
                               [ngClass]="descriptionClass" [innerHTML]="description | translate"></small>

                        <div *ngIf="showDescriptionContent">
                            <ng-content select="[descriptionContent]"></ng-content>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2 d-flex justify-content-end">
                <button *ngIf="showButton" type="button" class="btn btn-outline-primary rounded page-title-button"
                        (click)="buttonClick.emit()" [disabled]="disableButton">
                    {{buttonText | translate}}
                </button>
                <ng-content *ngIf="showContent" select="[rightContent]"></ng-content>  
            </div>
        </div>
    </div>

    <div class="col-12 px-0" *ngIf="showContent">
        <ng-content select="[newRowContent]"></ng-content>
    </div>
</div>
<div class="space-content">
    <app-custom-page-actions [customPageActions]="customPageActions"
                             *ngIf="hasActionsPermissions"></app-custom-page-actions>
    <div class="mt-4" *ngIf="hasSearch()">
        <app-quick-search [searchPlaceholder]="searchPlaceholder" (search)="onSearch($event)"></app-quick-search>
    </div>
</div>
