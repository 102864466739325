import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject, ViewChild
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    templateUrl: './player.component.html',
    styleUrls: ['./player.component.scss'],
})
export class PlayerComponent implements AfterViewInit  {
    @ViewChild('player') player: ElementRef;
    constructor(
        private dialogRef: MatDialogRef<PlayerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngAfterViewInit(): void {
        this.player.nativeElement.play();
    }

    get videoLink() {
        return this.data.videoLink;
    }

    close() {
        this.dialogRef.close();
    }
}
