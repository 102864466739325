<video
    #player
    content
    tabindex="0"
    class="player"
    (ended)="close()"
    controls="true"
>
    <source [src]="videoLink" type="video/mp4" />
</video>
