/* src/app/core/components/player-button/player-button.component.scss */
.btn-action {
  background-color: #5B6D80;
}
.btn-action:hover {
  background-color: #0760ef;
}
.btn-player-action {
  background-color: #0760ef;
  border-color: transparent !important;
  color: #fff;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  transition: all 0.1s ease-in-out;
}
.btn-player-action:focus,
.btn-player-action:hover,
.btn-player-action:active,
.btn-player-action:active:focus {
  outline: none;
  box-shadow: none;
  background-color: #0760ef;
}
.btn-player-action:disabled {
  background-color: #e1e8ee;
  cursor: not-allowed;
}
.btn-player-action i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: larger !important;
}
.btn-player-pulsing {
  background: rgb(7, 96, 239);
  box-shadow: 0 0 0 0 rgb(7, 96, 239);
  animation: pulse-blue 2s infinite;
  transform: scale(1);
}
@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(7, 96, 239, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(7, 96, 239, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(7, 96, 239, 0);
  }
}
/*# sourceMappingURL=player-button.component-UHPOIEQW.css.map */
