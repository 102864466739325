import {Component, Input, OnInit} from '@angular/core';
import {PlayerComponent} from '../player/player.component';
import {Subscription} from 'rxjs';
import {VideoGalleryService} from '../../services/video-gallery.service';
import {mergeMap, tap} from 'rxjs/operators';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {StorageService} from '../../services/storage.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-player-button',
    templateUrl: './player-button.component.html',
    styleUrls: ['./player-button.component.scss'],
})
export class PlayerButtonComponent implements OnInit {
    subscriptions: Subscription = new Subscription();

    @Input() videoName: string;
    @Input() bodyClass = 'mt-2';
    @Input() width = '30px';
    @Input() backgroundColor;
    @Input() videoGalleryDoc = 'portal';


    firstTimeAccessPage = true;
    videoLink: string;

    constructor(
        protected dialog: MatDialog,
        private videoGalleryService: VideoGalleryService,
        private storage: AngularFireStorage,
        private localStorage: StorageService
    ) {
    }

    ngOnInit(): void {
        if (this.videoName) {
            this.firstTimeAccessPage = !this.localStorage.get(this.videoName);
            this.localStorage.save(this.videoName, true);
        }
        this.subscriptions.add(
            this.videoGalleryService
                .getVideoByDocumentAndByProperty(this.videoGalleryDoc, this.videoName)
                .pipe(
                    mergeMap(video => video?.src ? (this.storage.ref(video.src).getDownloadURL()) : ''),
                )
                .subscribe((link: string) => {
                    this.videoLink = link;
                })
        );
    }

    openVideo() {
        this.dialog.open(PlayerComponent, {
            data: {videoLink: this.videoLink},
            height: '90%',
            maxWidth: 'none',
            panelClass: 'modal-video',
        }).afterClosed().subscribe(() => {
            this.firstTimeAccessPage = false;
        });
    }
}
