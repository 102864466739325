/* src/app/core/components/page-title/page-title.component.scss */
h1,
small {
  pointer-events: none;
}
.rounded {
  border-radius: 20px !important;
}
.sticky-title {
  top: 0;
  width: 100% !important;
  position: sticky;
  z-index: 1000 !important;
  background-color: white;
  padding: 1rem 30px 1rem 40px !important;
  box-shadow: 5px 2px 5px rgba(204, 204, 204, 0.8), -5px 2px 5px rgba(204, 204, 204, 0.8);
}
.sticky-title .page-title {
  margin-bottom: 0 !important;
}
.btn-action {
  background-color: #0760ef;
}
/*# sourceMappingURL=page-title.component-4NF7IRSC.css.map */
