import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CustomPageActionsComponent } from './custom-page-actions.component';
@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MatTooltipModule
    ],
    declarations: [CustomPageActionsComponent],
    exports: [CustomPageActionsComponent],
})
export class CustomPageActionsModule {}
