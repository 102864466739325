<div class="d-flex justify-content-end">
    <div
        *ngFor="let action of customPageActions; let i = index"
        class="mr-4"
        style="max-width: 200px"
        [attr.data-test]="'custom-page-action-' + i"
    >
        <div
            class="d-flex align-items-center {{direction}}"
            style="text-align: center"
            *ngIf="!action.hidden"
            [attr.data-test]="'custom-page-action-visible-' + i"
        >
            <button
                [class]="'btn-action ' + action.customClass"
                (click)="onClickAction(action)"
                [disabled]="checkIfDisabled(action)"
                [matTooltip]="
                    (checkIfDisabled(action)
                        ? action.disabledTooltip
                        : action.tooltip
                    ) | translate
                "
                [matTooltipShowDelay]="200"
                [attr.data-test]="'custom-page-action-button-' + i"
            >
                <i class="material-icons mr-0">{{ action.icon }}</i>
            </button>
            <span 
                [attr.data-test]="'custom-page-action-label-' + i"
                translate>
                {{ action.label }}
            </span>
        </div>
    </div>
</div>
