import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { BaseComponent } from '../../abstractions/base.component';

const CLASS_STICK = 'sticky-title';

type CustomPageActions = {
    onClick: any;
    label: string;
    icon: string;
    isDisabled?: any;
    customStyle?: any;
};

@Component({
    selector: 'app-page-title',
    templateUrl: './page-title.component.html',
    styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent
    extends BaseComponent
    implements AfterViewInit, OnDestroy
{
    @Input() title: string;
    @Input() description: string;
    @Input() showButton = false;
    @Input() disableButton = false;
    @Input() buttonText: string;
    @Input() showBackButton = false;
    @Input() urlBackToList: string | { url: string, params: any };
    @Input() searchPlaceholder = 'system.search-items';
    @Input() showContent = false;
    @Input() showDescriptionContent = false;
    @Input() titleClass = '';
    @Input() descriptionClass = '';
    @Input() viewMode = false;
    @Input() videoName: string;
    @Input() customPageActions: CustomPageActions[] = [];
    @Input() hasActionsPermissions = false;

    @Output() search = new EventEmitter();
    @Output() buttonClick = new EventEmitter();

    @ViewChild('topTitle')
    topTitle;

    constructor(elementRef: ElementRef) {
        super();
        /* Remove the component label */
        elementRef.nativeElement.title = '';
    }

    ngOnDestroy(): void {
        window.onscroll = null;
        this.topTitle.nativeElement.classList.remove(CLASS_STICK);
    }

    ngAfterViewInit(): void {
        const sticky = this.topTitle.nativeElement.offsetHeight;
        window.onscroll = () => {
            this.addClassSticky(sticky);
        };
    }

    private addClassSticky(sticky) {
        if (window.pageYOffset > sticky) {
            this.topTitle.nativeElement.classList.add(CLASS_STICK);
        } else {
            this.topTitle.nativeElement.classList.remove(CLASS_STICK);
        }
    }
    hasSearch() {
        return this.search.observers.length > 0;
    }

    onSearch(event) {
        this.search.emit(event);
    }

    backToList() {
        if (!this.urlBackToList) {
            history.back();
        } else {
            if (typeof this.urlBackToList !== 'string') {
                this.navigate([this.urlBackToList.url], this.urlBackToList.params);

            } else {
                this.navigate([this.urlBackToList]);
            }
        }
    }
}
