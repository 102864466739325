<div class="search-input">
  <input
      maxlength="150"
      aria-label="Quick search"
      type="text"
      placeholder="{{ searchPlaceholder | translate }}"
      (input)="onSearch(searchInput.value)"
      #searchInput>
  <i class="material-icons">search</i>
</div>
