import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { QuickSearchModule } from '../quick-search/quick-search.module';
import { PageTitleComponent } from './page-title.component';
import { PlayerModule } from './../player/player.module';
import { PlayerButtonModule } from './../player-button/player-button.module';
import { CustomPageActionsModule } from '../custom-page-actions/custom-page-actions.module';
@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        QuickSearchModule,
        MatRippleModule,
        PlayerModule,
        PlayerButtonModule,
        CustomPageActionsModule
    ],
    declarations: [PageTitleComponent],
    exports: [PageTitleComponent],
})
export class PageTitleModule {}
